<template>
  <div>
    <div class="row blacklist-page">
      <div class="col-lg-12">
        <KTPortlet v-bind:title="$t('MENU.BLACKLIST')">
          <template v-slot:toolbar>
            <b-button
              variant="primary"
              class="mr-4"
              @click="openCreateModal()"
              v-if="canAdd"
            >
              {{ $t("COMMON.NEW") }}
            </b-button>
            <b-button
              variant="danger"
              v-b-modal.confirm-1
              v-if="canRemove"
              :disabled="blacklistTable.selected.length == 0"
            >
              {{ $t("COMMON.DELETE") }}
            </b-button>
          </template>
          <template v-slot:body>
            <div class="row table-filter">
              <div class="col-sm-6">
                <b-form-group
                  :label="$t('COMMON.SEL_DOMAIN')"
                  label-for="select-domain"
                >
                  <model-list-select :list="selectDomain.allDomains"
                    v-model="selectDomain.selectedDomain"
                    option-value="domain"
                    option-text="domain"
                    :placeholder="$t('COMMON.SEL_DOMAIN')"
                    @input="onChangeDomain"
                    id="select-domain">
                  </model-list-select>
                </b-form-group>
              </div>
              <div class="col-sm-6">
                <v-text-field
                  v-model="search"
                  append-icon="search"
                  :label="$t('COMMON.SEARCH')"
                  class="cus-searchBox"
                  single-line
                  hide-details
                ></v-text-field>
              </div>
              <div class="col-sm-6">
                <v-checkbox
                  v-model="include_users"
                  class="include-check"
                  color="rgba(0, 0, 0, 0.54)"
                  :label="$t('FILTERING.BLACKLIST.INCLUDE_USERS_BLACKLIST')"
                  @change="getWB()"
                ></v-checkbox>
              </div>
            </div>
            <v-data-table
              v-model="blacklistTable.selected"
              :headers="headers"
              :items="blacklistTable.blacklist"
              :single-select="blacklistTable.singleSelect"
              :search="search"
              show-select
              item-key="id"
              class="elevation-1"
              ref="blacklistTable"
              :footer-props="{
                showFirstLastPage: true,
              }"
            >
              <template v-slot:item.action="{ item }">
                <v-icon
                  medium
                  color="dark"
                  @click="saveDeltem(item)"
                  v-b-modal.confirm-2
                  v-b-tooltip.hover
                  v-if="canRemove"
                  :title="$t('COMMON.DELETE')"
                >
                  delete
                </v-icon>
                <v-icon
                  medium
                  color="dark"
                  class="mr-2"
                  @click="showItemInfor(item)"
                  v-b-tooltip.hover
                  v-if="canAdd"
                  :title="$t('COMMON.EDIT')"
                >
                  edit
                </v-icon>
              </template>
            </v-data-table>
            <!-- ----------add blacklist modal------------- -->
            <b-modal
              id="addBlacklist"
              ref="addBlacklist"
              :title="
                isEdit == false
                  ? $t('FILTERING.BLACKLIST.ADD_BLACKLIST')
                  : editTitle
              "
            >
              <b-container fluid>
                <b-row class="my-1">
                  <b-col sm="5">
                    <label>{{ $t("COMMON.DOMAIN") }}</label>
                  </b-col>
                  <b-col sm="7">
                    <b-form-select
                      v-model="addForm.domain.selected"
                      value-field="domain_value"
                      text-field="domain"
                      :options="addForm.domain.data"
                    ></b-form-select>
                  </b-col>
                </b-row>
                <b-row class="my-4">
                  <b-col sm="5">
                    <label for="address">{{ $t("COMMON.ADDRESS") }}</label>
                  </b-col>
                  <b-col sm="7">
                    <b-form-input
                      id="address"
                      v-model="$v.addForm.address.$model"
                      :state="validateState('address')"
                      :placeholder="$t('COMMON.ADDRESS')"
                      aria-describedby="input-address-feedback"
                    ></b-form-input>
                    <b-form-invalid-feedback id="input-address-feedback">
                      {{ $t("VALIDATION.EMAIL_FIELD") }}
                    </b-form-invalid-feedback>
                  </b-col>
                </b-row>
                <!-- <b-row class="my-4" v-if="isEdit">
                  <b-col sm="5">
                    <label>{{ $t("COMMON.CHECK_SPAM") }}</label>
                  </b-col>
                  <b-col sm="7">
                    <b-form-select
                      v-model="addForm.check_spam.selected"
                      value-field="value"
                      text-field="name"
                      :options="check_spamData"
                    ></b-form-select>
                  </b-col>
                </b-row> -->
                <!-- <b-row class="my-4" v-if="isEdit">
                  <b-col sm="5">
                    <label>{{ $t("COMMON.CHECK_VIRUS") }}</label>
                  </b-col>
                  <b-col sm="7">
                    <b-form-select
                      v-model="addForm.check_virus.selected"
                      value-field="value"
                      text-field="name"
                      :options="check_virusData"
                    ></b-form-select>
                  </b-col>
                </b-row> -->
              </b-container>
              <template v-slot:modal-footer>
                <b-button variant="outline-secondary" v-on:click="modalClose()">
                  {{ $t("COMMON.CANCEL") }}
                </b-button>
                <b-button
                  variant="primary"
                  v-on:click="createItem()"
                  v-if="!isEdit"
                >
                  {{ $t("FILTERING.BLACKLIST.ADD_BLACKLIST") }}
                </b-button>
                <b-button
                  variant="primary"
                  v-on:click="updateItem()"
                  v-if="isEdit"
                >
                  {{ $t("FILTERING.BLACKLIST.UPDATE_BLACKLIST") }}
                </b-button>
              </template>
            </b-modal>

            <!-- ----------delete confirm modal------------- -->
            <!-- ----------- delete one blacklist confirm modal -->
            <b-modal
              id="confirm-2"
              :title="$t('COMMON.DEL_CONFIRM')"
              ref="delConfirm-modal"
            >
              <p class="my-1">
                {{ $t("COMMON.DEL_WARNING") }}
              </p>
              <template v-slot:modal-footer>
                <b-button
                  variant="outline-secondary"
                  v-on:click="confirmModalClose('delConfirm-modal')"
                >
                  {{ $t("COMMON.CANCEL") }}
                </b-button>
                <b-button variant="danger" v-on:click="deleteOneItem">
                  {{ $t("COMMON.DELETE") }}
                </b-button>
              </template>
            </b-modal>
            <!-- ----------- delete blacklists confirm modal -->
            <b-modal
              id="confirm-1"
              :title="$t('COMMON.DEL_CONFIRM')"
              ref="delConfirm-modal"
            >
              <p class="my-1">
                {{ $t("COMMON.DEL_WARNING") }}
              </p>
              <template v-slot:modal-footer>
                <b-button
                  variant="outline-secondary"
                  v-on:click="confirmModalClose('delConfirm-modal')"
                >
                  {{ $t("COMMON.CANCEL") }}
                </b-button>
                <b-button variant="danger" v-on:click="deleteItems">
                  {{ $t("COMMON.DELETE") }}
                </b-button>
              </template>
            </b-modal>
            <!-- ----------delete confirm modal end------------- -->
          </template>
        </KTPortlet>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import { validationMixin } from "vuelidate";
import KTPortlet from "@/views/partials/content/Portlet.vue";
import { postRequest } from "@/api/request.js";
import { required } from "vuelidate/lib/validators";
/* ---custom validation rule---- */
const mustBeDot = (value) =>
  value.lastIndexOf(".") > 0 && value.length - value.lastIndexOf(".") != 1;

export default {
  mixins: [validationMixin],
  name: "Blacklist",
  components: {
    KTPortlet,
  },
  data: () => ({
    search: "",
    blacklistTable: {
      singleSelect: false,
      blacklist: [],
      selected: [],
    },
    selectDomain: {
      selectedDomain: {},
      allDomains: [],
    },
    addForm: {
      domain: {
        selected: "",
        data: [],
      },
      address: "",
      check_spam: {
        selected: 1,
      },
      check_virus: {
        selected: 1,
      },
    },
    delItem: "",
    isEdit: false,
    updateLid: "",
    editTitle: "",
    include_users: false,
  }),
  computed: {
    headers: function () {
      return this.include_users
        ? [
            { text: this.$t("COMMON.ADDRESS"), value: "address" },
            { text: this.$t("COMMON.USERNAME"), value: "username" },
            {
              text: this.$t("COMMON.ACTION"),
              value: "action",
              sortable: false,
            },
          ]
        : [
            { text: this.$t("COMMON.ADDRESS"), value: "address" },
            {
              text: this.$t("COMMON.ACTION"),
              value: "action",
              sortable: false,
            },
          ];
    },
    check_spamData: function () {
      return [
        { name: this.$t("COMMON.YES"), value: 1 },
        { name: this.$t("COMMON.NO"), value: 0 },
      ];
    },
    check_virusData: function () {
      return [
        { name: this.$t("COMMON.YES"), value: 1 },
        { name: this.$t("COMMON.NO"), value: 0 },
      ];
    },
    canAdd: function () {
      return this.hasPermission("filtering", 5);
    },
    canRemove: function () {
      return this.hasPermission("filtering", 7);
    },
  },
  created() {
    postRequest({
      action: "getAllDomains",
      token: localStorage.id_token,
    }).then((res) => {
      if (res) {
        if (!res.returncode) {
          res.returndata = [{ domain: res.returndata }];
        }
        this.selectDomain.allDomains = res.returndata;
        this.selectDomain.selectedDomain = res.returndata[0];
        this.addForm.domain.data = res.returndata;
        let that = this;
        this.addForm.domain.data.forEach(function (item, index) {
          that.addForm.domain.data[index].domain_value = item.domain;
        });
        this.addForm.domain.selected = this.addForm.domain.data[0].domain_value;
        this.getBlacklistReqest(this.selectDomain.selectedDomain.domain);
      }
    });
  },
  validations: {
    addForm: {
      address: {
        required,
        mustBeDot,
      },
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("MENU.FILTERING") + " -> " + this.$t("MENU.BLACKLIST") },
    ]);
  },
  methods: {
    /* -----validation---- */
    validateState(name) {
      const { $dirty, $error } = this.$v.addForm[name];
      return $dirty ? !$error : null;
    },
    addformReset() {
      this.addForm.address = "";
      this.addForm.check_spam.selected = this.check_spamData[0].value;
      this.addForm.check_virus.selected = this.check_virusData[0].value;
    },
    /*  -----modal close------- */
    modalClose() {
      this.$nextTick(() => {
        this.$v.$reset();
      });
      this.addformReset();
      this.$refs["addBlacklist"].hide();
    },
    confirmModalClose(modalItem) {
      this.$refs[modalItem].hide();
    },

    /* ---------read item---------- */
    getBlacklistReqest(domain) {
      this.blacklistTable.blacklist = [];
      this.blacklistTable.selected = [];
      postRequest({
        action: "getWBLists",
        token: localStorage.id_token,
        type: 2,
        domain: domain,
        include_userslist: this.include_users ? 1 : 0,
      }).then((res) => {
        if (res.returncode) {
          for (let i = 0; i < res.returndata.length; i++) {
            if (res.returndata[i].check_spam == 0) {
              res.returndata[i].check_spam = this.$t("COMMON.NO");
            } else {
              res.returndata[i].check_spam = this.$t("COMMON.YES");
            }
            if (res.returndata[i].check_virus == 0) {
              res.returndata[i].check_virus = this.$t("COMMON.NO");
            } else {
              res.returndata[i].check_virus = this.$t("COMMON.YES");
            }
          }
          this.blacklistTable.blacklist = res.returndata;
        } else {
          this.blacklistTable.blacklist = [];
        }
      });
    },
    getBlacklistForSelectedDomain(selectedDomain) {
      this.getBlacklistReqest(selectedDomain);
    },

    /* ------create item---- */
    openCreateModal() {
      this.isEdit = false;
      this.addForm.domain.selected = this.selectDomain.selectedDomain.domain;
      this.$refs["addBlacklist"].show();
    },
    createItem() {
      this.$v.addForm.$touch();
      if (this.$v.addForm.address.$anyError) {
        return;
      }
      const selectedDomainForAdd = this.addForm.domain.selected;
      const selectedDomainForPage = this.selectDomain.selectedDomain.domain;
      postRequest({
        action: "addBlacklist",
        token: localStorage.id_token,
        address: this.addForm.address,
        check_spam: this.addForm.check_spam.selected,
        check_virus: this.addForm.check_virus.selected,
        domain: selectedDomainForAdd,
      }).then((res) => {
        this.modalClose();
        if (res.returncode) {
          if (selectedDomainForAdd == selectedDomainForPage)
            this.getBlacklistReqest(selectedDomainForAdd);
          this.makeToastVariant("success", res.returndata);
        } else {
          this.makeToastVariant("danger", res.returndata);
        }
        this.addformReset();
      });
    },
    /* ------create item end---- */
    /* -------delete item----- */
    saveDeltem(item) {
      this.delItem = item;
    },

    deleteRequest(lid) {
      postRequest({
        action: "removeWBList",
        token: localStorage.id_token,
        lid: lid,
      }).then((res) => {
        this.confirmModalClose("delConfirm-modal");
        if (res.returncode) {
          this.makeToastVariant("success", res.returndata);
          for (let i = 0; i < this.blacklistTable.blacklist.length; i++) {
            if (this.blacklistTable.blacklist[i].id == lid) {
              this.blacklistTable.blacklist.splice(i, 1);
            }
          }
        } else {
          this.makeToastVariant("danger", res.returndata);
        }
      });
    },
    deleteOneItem() {
      const item = this.delItem;
      this.deleteRequest(item.id);
    },
    deleteItems() {
      if (this.blacklistTable.selected.length > 0) {
        this.blacklistTable.selected.forEach((el) => {
          this.deleteRequest(el.id);
        });
      }
    },
    /* -------edit item----- */
    showItemInfor(item) {
      const lid = item.id;
      this.editTitle = this.$t("COMMON.UPDATE") + item.address;
      this.isEdit = true;
      this.updateLid = item.id;
      postRequest({
        action: "getWBList",
        token: localStorage.id_token,
        lid: lid,
      }).then((res) => {
        if (res.returncode) {
          this.$refs["addBlacklist"].show();
          this.addForm.domain.selected = res.returndata.domain;
          this.addForm.address = res.returndata.address;
          // this.addForm.check_spam.selected = res.returndata.check_spam;
          // this.addForm.check_virus.selected = res.returndata.check_virus;
        } else {
          this.makeToastVariant("danger", res.returndata);
        }
      });
    },

    updateItem() {
      this.$v.addForm.$touch();
      if (this.$v.addForm.address.$anyError) {
        return;
      }
      const selectedDomainForAdd = this.addForm.domain.selected;
      const selectedDomainForPage = this.selectDomain.selectedDomain.domain;
      postRequest({
        action: "updateBlacklist",
        token: localStorage.id_token,
        lid: this.updateLid,
        address: this.addForm.address,
        check_spam: this.addForm.check_spam.selected,
        check_virus: this.addForm.check_virus.selected,
        domain: selectedDomainForAdd,
      }).then((res) => {
        this.modalClose("addWhitelist");
        if (res.returncode) {
          if (selectedDomainForAdd == selectedDomainForPage)
            this.getBlacklistReqest(selectedDomainForAdd);
          this.makeToastVariant("success", res.returndata);
        } else {
          this.makeToastVariant("danger", res.returndata);
        }
        this.addformReset();
      });
    },

    getWB() {
      this.getBlacklistReqest(this.selectDomain.selectedDomain.domain);
    },

    /* ----notify---- */
    makeToastVariant(variant = null, msg) {
      let title = this.$t("COMMON.ERROR");
      if (variant == "success") {
        title = this.$t("COMMON.SUCCESS");
      }
      this.$bvToast.toast(msg, {
        title: title,
        variant: variant,
        toaster: "b-toaster-top-center",
        // autoHideDelay: 5000,
        solid: true,
      });
    },
    /* ---- change domain dropdown ---- */
    onChangeDomain(newValue) {
      this.getBlacklistForSelectedDomain(newValue.domain);
    },
  },
};
</script>
